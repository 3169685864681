import React from "react"
import Recipe from "../components/recipe"
import chocolateChipCookies from "../data/chocolate-chip-cookies.json"

const ChocolateChipCookiesPage = () => {
  return (
    <div>
      <Recipe {...chocolateChipCookies} />
    </div>
  )
}

export default ChocolateChipCookiesPage
