import React from "react"

const Recipe = ({ title, description, ingredients, steps }) => {
  return (
    <div>
      <h2>{title}</h2>
      <p>{description}</p>
      <h3>Ingredients:</h3>
      <ul>
        {ingredients.map((ingredient, index) => (
          <li key={index}>{ingredient}</li>
        ))}
      </ul>
      <h3>Steps:</h3>
      <ol>
        {steps.map((step, index) => (
          <li key={index}>{step}</li>
        ))}
      </ol>
    </div>
  )
}

export default Recipe
